import React from 'react';
import { Typography, Container } from '@mui/material';

function Footer() {
  return (
    <footer style={{ marginTop: 'auto', padding: '2rem 1rem', textAlign: 'center' }}> {/* �������� ������� ������ */}
      <Container>
        <Typography variant="body2" color="textSecondary" sx={{ marginBottom: '1rem' }}> {/* ������� �������������� ������ */}
          &copy; 2024 Liberty Project Block Explorer. All rights reserved.
        </Typography>
      </Container>
    </footer>
  );
}

export default Footer;
