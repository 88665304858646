// src/pages/TransactionPage.js
import React, { useEffect, useState } from 'react';
import { Container, Typography, CircularProgress, Box, List, ListItem, ListItemText } from '@mui/material';
import { getLatestTransactions } from '../services/api';

function TransactionPage() {
  const [transactions, setTransactions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchTransactions = async () => {
      setLoading(true);
      try {
        const response = await getLatestTransactions(10); // �������� ��������� 10 ����������
        setTransactions(response.data);
        setError(null);
      } catch (err) {
        console.error("Failed to load transactions:", err);
        setError("Failed to load transactions.");
      } finally {
        setLoading(false);
      }
    };

    fetchTransactions();
  }, []);

  if (loading) {
    return (
      <Box display="flex" alignItems="center" justifyContent="center" mt={4}>
        <CircularProgress />
        <Typography sx={{ ml: 2 }}>Loading...</Typography>
      </Box>
    );
  }

  if (error) {
    return <Typography color="error" align="center">{error}</Typography>;
  }

  return (
    <Container>
      <Typography variant="h4" gutterBottom>Recent Transactions</Typography>
      {transactions.length > 0 ? (
        <List>
          {transactions.map((tx) => (
            <ListItem key={tx.hash} divider>
              <ListItemText
                primary={`Transaction Hash: ${tx.hash}`}
                secondary={
                  <>
                    <Typography variant="body2"><strong>Block Number:</strong> {tx.blockNumber}</Typography>
                    <Typography variant="body2"><strong>From:</strong> {tx.from}</Typography>
                    <Typography variant="body2"><strong>To:</strong> {tx.to || 'Contract Creation'}</Typography>
                    <Typography variant="body2"><strong>Value:</strong> {tx.value} LBRT</Typography>
                    <Typography variant="body2"><strong>Gas:</strong> {tx.gas}</Typography>
                    <Typography variant="body2"><strong>Gas Price:</strong> {tx.gasPrice}</Typography>
                  </>
                }
              />
            </ListItem>
          ))}
        </List>
      ) : (
        <Typography>No transactions found.</Typography>
      )}
    </Container>
  );
}

export default TransactionPage;
