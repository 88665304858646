import React, { useContext } from 'react';
import { AppBar, Toolbar, Typography, Box, Button, IconButton, Container, useTheme } from '@mui/material';
import { Link } from 'react-router-dom';
import { LightMode, DarkMode } from '@mui/icons-material';
import { ColorModeContext } from '../ThemeContext';

function Header() {
  const colorMode = useContext(ColorModeContext);
  const theme = useTheme(); // �������� ���� ��� ������������ �����

  const addNetworkToMetaMask = async () => {
    if (typeof window.ethereum !== 'undefined') {
      try {
        await window.ethereum.request({
          method: 'wallet_addEthereumChain',
          params: [
            {
              chainId: '0x526F', // ID ����� ����
              chainName: 'Liberty Project Testnet',
              nativeCurrency: {
                name: 'tLBRT',
                symbol: 'tLBRT',
                decimals: 18,
              },
              rpcUrls: ['https://rpc.libertyproject.space'],
              blockExplorerUrls: ['https://explorer.libertyproject.space'],
            },
          ],
        });
        alert('Network successfully added to MetaMask!');
      } catch (error) {
        alert('Error adding network: ' + error.message);
      }
    } else {
      alert('MetaMask is not installed. Please install MetaMask and try again.');
    }
  };

  return (
    <Box
      sx={{
        width: '100%', // ����� ������ �����
        display: 'flex',
        justifyContent: 'center',
        paddingY: 1,
      }}
    >
      <Box
        sx={{
          width: '1100px', // ������, ����������� � ��������� �������
          backgroundColor: theme.palette.mode === 'light' ? '#f5f5f5' : '#424242', // ������� ��� light, ������ ��� dark
          borderRadius: '8px', // ������������ ����
          padding: '0 16px',
          boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.2)', // ������ ����
        }}
      >
        <Toolbar
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          {/* ����� ����� � ��������� � ��������� */}
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
            <Link
              to="/"
              style={{
                display: 'flex',
                alignItems: 'center',
                textDecoration: 'none',
                color: 'inherit',
              }}
            >
              <img
                src="/logo.png"
                alt="Liberty Project Explorer Logo"
                style={{ height: '40px' }}
              />
              <Typography variant="h6" component="div">
                Liberty Project Explorer
              </Typography>
            </Link>
          </Box>

          {/* ������ ����� � �������� � �������������� ���� */}
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
            <Button color="inherit" component={Link} to="/addresses">
              Addresses
            </Button>
            <Button color="inherit" onClick={addNetworkToMetaMask}>
              Add Network to MetaMask
            </Button>
            <Button color="inherit" component={Link} to="/">
              Home
            </Button>
            <IconButton onClick={colorMode.toggleColorMode} color="inherit">
              {colorMode.mode === 'light' ? <DarkMode /> : <LightMode />}
            </IconButton>
          </Box>
        </Toolbar>
      </Box>
    </Box>
  );
}

export default Header;
