import React, { useEffect, useState } from 'react';
import { Container, Typography, CircularProgress, Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { getAddresses } from '../services/api';

function AddressPage() {
  const [addresses, setAddresses] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchAddresses = async () => {
      try {
        const response = await getAddresses();
        console.log("Data received from API:", response.data); // ��� ��� �������� ������
        const sortedAddresses = response.data.sort((a, b) => parseFloat(b.balance) - parseFloat(a.balance)); // ���������� �� �������
        setAddresses(sortedAddresses);
        setError(null);
      } catch (err) {
        console.error("Failed to load addresses:", err);
        setError("Failed to load addresses.");
      } finally {
        setLoading(false);
      }
    };

    fetchAddresses();
  }, []);

  const handleAddressClick = (address) => {
    console.log(`Navigating to /addresses/${address}`); // ��� ��������
    navigate(`/addresses/${address}`);
  };

  if (loading) {
    return (
      <Box display="flex" alignItems="center" justifyContent="center" mt={4}>
        <CircularProgress />
        <Typography sx={{ ml: 2 }}>Loading...</Typography>
      </Box>
    );
  }

  if (error) {
    return <Typography color="error" align="center">{error}</Typography>;
  }

  return (
    <Container>
      <Typography variant="h4" gutterBottom>Liberty Addresses</Typography>
      <Typography variant="h6" gutterBottom>Total Addresses: {addresses.length}</Typography>

      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell><strong>Address</strong></TableCell>
              <TableCell align="center"><strong>Transactions</strong></TableCell>
              <TableCell align="right"><strong>Balance</strong></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {addresses.length > 0 ? (
              addresses.map((address) => (
                <TableRow
                  key={address.address}
                  hover
                  style={{ cursor: 'pointer' }}
                  onClick={() => handleAddressClick(address.address)}
                >
                  <TableCell>{address.address}</TableCell>
                  <TableCell align="center">{address.txCount}</TableCell>
                  <TableCell align="right">{Number(address.balance).toFixed(8)} LBRT</TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={3} align="center">
                  No addresses available
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Container>
  );
}

export default AddressPage; 
