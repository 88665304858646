// src/components/BlockCard.js
import React from 'react';
import { Card, CardContent, Typography, Box } from '@mui/material';
import { Link } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';

function BlockCard({ block }) {
  const theme = useTheme();

  if (!block) {
    return <Typography>Loading...</Typography>;
  }

  return (
    <Card
      sx={{
        borderRadius: 2,
        boxShadow: '0px 10px 30px rgba(0, 0, 0, 0.3)',
        backgroundColor: theme.palette.background.paper,
        border: `1px solid ${theme.palette.divider}`,
        transition: 'transform 0.3s, box-shadow 0.3s',
        '&:hover': {
          transform: 'scale(1.03)',
          boxShadow: '0px 15px 40px rgba(0, 0, 0, 0.5)',
        },
      }}
    >
      <CardContent>
        <Typography variant="h6" component="div" color="primary" sx={{ fontWeight: 'bold' }}>
          <Link to={`/block/${block.number}`} style={{ textDecoration: 'none', color: theme.palette.primary.main }}>
            Block #{block.number}
          </Link>
        </Typography>
        <Typography variant="body2" color="text.secondary">
          Timestamp: {new Date(block.timestamp).toLocaleString()}
        </Typography>
        <Typography variant="body2" color="text.secondary">
          Transactions: {block.transactionCount ?? 0}
        </Typography>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Typography
            variant="body2"
            color="text.secondary"
            sx={{ fontWeight: 'bold', fontSize: '0.76rem', mr: 0.1 }} // ������� ������ ������
          >
            Miner:
          </Typography>
          <Link
            to={`/addresses/${block.miner}`}
            style={{
              color: theme.palette.text.secondary,
              textDecoration: 'none',
              fontSize: '0.76rem',
              fontWeight: 'bold',
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              display: 'inline-block',
              maxWidth: '100%',
            }}
          >
            {block.miner}
          </Link>
        </Box>
      </CardContent>
    </Card>
  );
}

export default BlockCard;
