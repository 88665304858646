import React, { useEffect, useState } from 'react';
import { Container, Typography, Grid, Stack, Button, Box } from '@mui/material';
import { Link } from 'react-router-dom';
import BlockCard from '../components/BlockCard';
import TransactionCard from '../components/TransactionCard';
import NetworkStats from '../components/NetworkStats';
import { getLatestBlocks, getLatestTransactions } from '../services/api';

function HomePage() {
  const [blocks, setBlocks] = useState([]);
  const [transactions, setTransactions] = useState([]);
  const [error, setError] = useState(null);
  const [initialLoading, setInitialLoading] = useState(true);

  const fetchData = (showLoading = false) => {
    if (showLoading) setInitialLoading(true);

    Promise.all([getLatestBlocks(10), getLatestTransactions()])
      .then(([blocksResponse, transactionsResponse]) => {
        setBlocks(blocksResponse.data.blocks || []);
        setTransactions(transactionsResponse.data || []);
        setError(null);
      })
      .catch(err => {
        console.error("Error fetching data:", err);
        setError("Failed to load latest blocks and transactions.");
      })
      .finally(() => {
        if (showLoading) setInitialLoading(false);
      });
  };

  useEffect(() => {
    // First fetch with loading indicator
    fetchData(true);

    // Set interval to fetch data every 10 seconds without showing loading indicator
    const intervalId = setInterval(() => {
      fetchData(false);
    }, 10000);

    // Clear interval on component unmount
    return () => clearInterval(intervalId);
  }, []);

  if (initialLoading) {
    return <Typography>Loading...</Typography>;
  }

  if (error) {
    return <Typography color="error">{error}</Typography>;
  }

  return (
    <Container>
      <NetworkStats />

      <Box display="flex" alignItems="center" justifyContent="space-between" mt={2} mb={1}>
        <Typography variant="h4">Latest Blocks</Typography>
        <Button component={Link} to="/blocks" variant="contained" color="primary">
          View All Blocks
        </Button>
      </Box>
      <Grid container spacing={3}>
        {blocks.length > 0 ? (
          blocks.map((block) => (
            <Grid item key={block.number} xs={12} sm={6} md={4}>
              <BlockCard block={block} />
            </Grid>
          ))
        ) : (
          <Typography>No blocks available</Typography>
        )}
      </Grid>

      <Box display="flex" alignItems="center" justifyContent="space-between" mt={4} mb={1}>
        <Typography variant="h4">Latest Transactions</Typography>
        <Button component={Link} to="/transactions" variant="contained" color="primary">
          View All Transactions
        </Button>
      </Box>
      <Stack spacing={2}>
        {transactions.length > 0 ? (
          transactions.map((tx) => (
            <TransactionCard key={tx.hash} transaction={tx} />
          ))
        ) : (
          <Typography>No transactions available</Typography>
        )}
      </Stack>
    </Container>
  );
}

export default HomePage;
