// src/App.js
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { CssBaseline, Container } from '@mui/material';
import ThemeContextProvider from './ThemeContext';
import HomePage from './pages/HomePage';
import BlocksPage from './pages/BlocksPage';
import BlockDetailsPage from './pages/BlockDetailsPage';
import TransactionPage from './pages/TransactionPage';
import AddressPage from './pages/AddressPage';
import AddressDetailsPage from './pages/AddressDetailsPage';
import StatsPage from './pages/StatsPage';
import Header from './components/Header';
import Footer from './components/Footer';
import SearchBar from './components/SearchBar';
import TransactionDetailsPage from './pages/TransactionDetailsPage';

function AppContent() {
  return (
    <>
      <Header />
      <Container maxWidth="lg">
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '16px' }}>
          <SearchBar />
        </div>
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/blocks" element={<BlocksPage />} />
          <Route path="/block/:blockNumber" element={<BlockDetailsPage />} />
          <Route path="/transactions" element={<TransactionPage />} />
          <Route path="/transactions/:hash" element={<TransactionDetailsPage />} />
          <Route path="/addresses" element={<AddressPage />} />
          <Route path="/addresses/:address" element={<AddressDetailsPage />} />
          <Route path="/stats" element={<StatsPage />} />
        </Routes>
        <Footer />
      </Container>
    </>
  );
}

function App() {
  return (
    <ThemeContextProvider>
      <CssBaseline />
      <Router>
        <AppContent />
      </Router>
    </ThemeContextProvider>
  );
}

export default App;
