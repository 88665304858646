import React, { createContext, useState, useMemo, useEffect } from 'react';
import { ThemeProvider, createTheme } from '@mui/material/styles';

// ������� �������� ��� ���������� ������� �����
export const ColorModeContext = createContext({ toggleColorMode: () => {} });

export default function ThemeContextProvider({ children }) {
  // �������������� ���� �� localStorage, �� ��������� - 'light'
  const [mode, setMode] = useState(() => {
    const savedMode = localStorage.getItem('theme');
    return savedMode ? savedMode : 'light';
  });

  const colorMode = useMemo(
    () => ({
      toggleColorMode: () => {
        setMode((prevMode) => {
          const newMode = prevMode === 'light' ? 'dark' : 'light';
          localStorage.setItem('theme', newMode); // ��������� ����� ���� � localStorage
          return newMode;
        });
      },
    }),
    []
  );

  const theme = useMemo(
    () =>
      createTheme({
        palette: {
          mode,
          ...(mode === 'light'
            ? {
                background: {
                  default: '#f4f6f8',
                  paper: '#ffffff',
                },
                primary: {
                  main: '#1976d2',
                },
                text: {
                  primary: '#000000', // ������ ������ ���� ��� ������� ����
                  secondary: '#5f6368',
                },
              }
            : {
                background: {
                  default: '#1c1c1e',
                  paper: '#2c2c2e',
                },
                primary: {
                  main: '#90caf9',
                },
                text: {
                  primary: '#ffffff', // ����� ����� ��� ������ ����
                  secondary: '#a8a8a8',
                },
              }),
        },
        typography: {
          fontFamily: 'Roboto, Arial, sans-serif',
        },
      }),
    [mode]
  );

  useEffect(() => {
    // ��������� ������� ���� � localStorage ��� ���������
    localStorage.setItem('theme', mode);
  }, [mode]);

  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>{children}</ThemeProvider>
    </ColorModeContext.Provider>
  );
}
