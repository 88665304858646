import React, { useEffect, useState } from 'react';
import { Card, CardContent, Typography, Grid } from '@mui/material';
import { Line } from 'react-chartjs-2';
import axios from 'axios';
import {
  Chart as ChartJS,
  LineElement,
  CategoryScale,
  LinearScale,
  PointElement,
  Tooltip,
  Legend,
} from 'chart.js';

ChartJS.register(LineElement, CategoryScale, LinearScale, PointElement, Tooltip, Legend);

function NetworkStats() {
  const [stats, setStats] = useState({
    avgBlockTime: 'Loading...',
    totalBlocks: 'Loading...',
    totalTransactions: 'Loading...',
    activeAddresses: 'Loading...',
    difficulty: 'Loading...',
    networkHashrate: 'Loading...',
    difficultyAndHashrateHistory: null,
  });

  useEffect(() => {
    axios.get('/api/stats')
      .then(response => {
        console.log("Fetched network stats:", response.data.difficultyAndHashrateHistory); // Debugging data
        setStats(response.data);
      })
      .catch(error => {
        console.error('Error fetching network stats:', error);
      });
  }, []);

  const { difficultyAndHashrateHistory } = stats;
  const chartReady = difficultyAndHashrateHistory && difficultyAndHashrateHistory.length > 1;

  // ������ ��� ���������
  const difficultyChartData = chartReady
    ? {
        labels: difficultyAndHashrateHistory.map(entry => entry.number),
        datasets: [
          {
            label: 'Difficulty',
            data: difficultyAndHashrateHistory.map(entry => Number(entry.difficulty)),
            borderColor: 'rgba(75, 192, 192, 1)',
            backgroundColor: 'rgba(75, 192, 192, 0.2)',
          },
        ],
      }
    : null;

  const difficultyChartOptions = {
    responsive: true,
    scales: {
      y: {
        title: {
          display: true,
          text: 'Difficulty',
        },
      },
      x: {
        title: {
          display: true,
          text: 'Block Number',
        },
      },
    },
  };

  // ������ ��� �������� � �������������� `difficultyAndHashrateHistory` ��� ������� �����
  const hashrateChartData = chartReady
    ? {
        labels: difficultyAndHashrateHistory.map(entry => entry.number),
        datasets: [
          {
            label: 'Network Hashrate (MH/s)',
            data: difficultyAndHashrateHistory.map(entry => parseFloat(entry.hashrate)),
            borderColor: 'rgba(255, 99, 132, 1)',
            backgroundColor: 'rgba(255, 99, 132, 0.2)',
          },
        ],
      }
    : null;

  const hashrateChartOptions = {
    responsive: true,
    scales: {
      y: {
        title: {
          display: true,
          text: 'Network Hashrate (MH/s)',
        },
        beginAtZero: true,
      },
      x: {
        title: {
          display: true,
          text: 'Block Number',
        },
      },
    },
  };

  return (
    <div>
      <Grid container spacing={3} style={{ marginBottom: '20px' }}>
        {Object.entries(stats).map(([key, value]) => (
          key !== 'difficultyAndHashrateHistory' && (
            <Grid item xs={6} sm={3} key={key}>
              <Card>
                <CardContent>
                  <Typography variant="h6">
                    {key.replace(/([A-Z])/g, ' $1')}
                  </Typography>
                  <Typography variant="body2">
                    {value}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          )
        ))}
      </Grid>

      {chartReady ? (
        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <Card>
              <CardContent>
                <Typography variant="h6" gutterBottom>
                  Network Difficulty
                </Typography>
                <Line data={difficultyChartData} options={difficultyChartOptions} />
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} md={6}>
            <Card>
              <CardContent>
                <Typography variant="h6" gutterBottom>
                  Network Hashrate (MH/s)
                </Typography>
                <Line data={hashrateChartData} options={hashrateChartOptions} />
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      ) : (
        <Typography>Loading chart data...</Typography>
      )}
    </div>
  );
}

export default NetworkStats;
