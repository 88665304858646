import React, { useState } from 'react';
import { TextField, Button, InputAdornment } from '@mui/material';
import { Search } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';

function SearchBar() {
  const [searchQuery, setSearchQuery] = useState('');
  const navigate = useNavigate();

  const handleSearch = () => {
    const normalizedQuery = searchQuery.trim().toLowerCase(); // �������� � ������� ��������

    if (normalizedQuery.startsWith('0x')) {
      // ���� ������ ���������� � "0x", ���������, �������� �� ��� ����� ���������� ��� �������
      if (normalizedQuery.length === 66) {
        // 66 �������� - �������� ����� ���� ����������
        navigate(`/transactions/${normalizedQuery}`);
      } else if (normalizedQuery.length === 42) {
        // 42 ������� - �������� ����� ������
        navigate(`/addresses/${normalizedQuery}`);
      } else {
        alert('Invalid search query');
      }
    } else if (!isNaN(normalizedQuery)) {
      // ���� ��� �������� ��������, ������������, ��� ��� ����� �����
      navigate(`/block/${normalizedQuery}`);
    } else {
      alert('Invalid search query');
    }
  };

  return (
    <TextField
      sx={{
        width: '1100px', // ������ ������������� ������ (����� ��������)
        margin: '0 auto', // ����������
      }}
      variant="outlined"
      placeholder="Search by Address, TxHash, or Block"
      value={searchQuery}
      onChange={(e) => setSearchQuery(e.target.value)}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <Button
              variant="contained"
              color="primary"
              onClick={handleSearch}
            >
              <Search sx={{ color: 'white' }} />
            </Button>
          </InputAdornment>
        ),
      }}
    />
  );
}

export default SearchBar;
