// pages/BlocksPage.js
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Container, Typography, List, ListItem, ListItemText, CircularProgress, Box, IconButton } from '@mui/material';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { getLatestBlocks } from '../services/api';

function BlocksPage() {
  const [blocks, setBlocks] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [page, setPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const limit = 10;

  useEffect(() => {
    fetchBlocks(page);
  }, [page]);

  const fetchBlocks = (page) => {
    setLoading(true);
    getLatestBlocks(limit, page * limit)
      .then(response => {
        setBlocks(response.data.blocks || []);
        setTotalPages(Math.ceil(response.data.total / limit));
        setError(null);
      })
      .catch(err => {
        console.error("Error fetching blocks:", err);
        setError("Failed to load blocks.");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleNextPage = () => {
    if (page < totalPages - 1) setPage((prevPage) => prevPage + 1);
  };

  const handlePrevPage = () => setPage((prevPage) => Math.max(prevPage - 1, 0));

  if (loading) {
    return (
      <Box display="flex" alignItems="center" justifyContent="center" mt={4}>
        <CircularProgress />
        <Typography sx={{ ml: 2 }}>Loading...</Typography>
      </Box>
    );
  }

  if (error) {
    return <Typography color="error" align="center">{error}</Typography>;
  }

  return (
    <Container>
      <Typography variant="h4" gutterBottom>Blocks</Typography>

      <List>
        {blocks.map((block) => (
          <ListItem
            key={block.number}
            divider
            component={Link}
            to={`/block/${block.number}`} // ��������� ������ �� ��������� �������� �����
            style={{ textDecoration: 'none', color: 'inherit' }}
          >
            <ListItemText
              primary={
                <Typography variant="h6" color="primary">
                  #{block.number} - Block
                </Typography>
              }
              secondary={
                <>
                  <Typography variant="body2" color="textSecondary">
                    {block.transactionCount || 0} transactions | {block.size || 'Unknown size'} bytes | {Math.floor((Date.now() - new Date(block.timestamp)) / 1000)} seconds ago
                  </Typography>
                  <Typography variant="body2" color="textSecondary">
                    Miner: {block.miner || 'Unknown miner'}
                  </Typography>
                  <Typography variant="body2" color="textSecondary">
                    {block.gasLimit} Gas Limit | {block.gasUsed} ({block.gasLimit ? Math.round((block.gasUsed / block.gasLimit) * 100) : 0}%) Gas Used
                  </Typography>
                </>
              }
            />
          </ListItem>
        ))}
      </List>

      <Box display="flex" justifyContent="space-between" alignItems="center" mt={3}>
        <IconButton onClick={handlePrevPage} disabled={page === 0}>
          <ArrowBackIosIcon />
        </IconButton>
        <Typography>Page {page + 1} of {totalPages}</Typography>
        <IconButton onClick={handleNextPage} disabled={page >= totalPages - 1}>
          <ArrowForwardIosIcon />
        </IconButton>
      </Box>
    </Container>
  );
}

export default BlocksPage;
