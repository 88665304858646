import React from 'react';

function StatsPage() {
  return (
    <div>
      <h1>Network Stats</h1>
      <p>This page will display network statistics and graphs.</p>
    </div>
  );
}

export default StatsPage;
