import React, { useEffect, useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import { getBlockByNumber, getTransactionsByBlockNumber } from '../services/api';
import {
  Container,
  Typography,
  CircularProgress,
  Box,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
} from '@mui/material';

function BlockDetailsPage() {
  const { blockNumber } = useParams();
  const [block, setBlock] = useState(null);
  const [transactions, setTransactions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    setLoading(true);

    getBlockByNumber(blockNumber)
      .then((response) => {
        setBlock(response.data);
        return getTransactionsByBlockNumber(blockNumber);
      })
      .then((response) => {
        setTransactions(response.data || []);
        setError(null);
      })
      .catch((err) => {
        console.error('Error fetching block details or transactions:', err);
        setError('Failed to load block or transactions');
      })
      .finally(() => {
        setLoading(false);
      });
  }, [blockNumber]);

  if (loading) {
    return (
      <Box display="flex" alignItems="center" justifyContent="center" mt={4}>
        <CircularProgress />
        <Typography sx={{ ml: 2 }}>Loading...</Typography>
      </Box>
    );
  }

  if (error) {
    return <Typography color="error" align="center">{error}</Typography>;
  }

  if (!block) return null;

  // Helper function to shorten transaction IDs
  const shortenHash = (hash) => `${hash.slice(0, 10)}...${hash.slice(-10)}`;

  return (
    <Container>
      <Button
        variant="contained"
        color="primary"
        sx={{ mb: 2 }}
        onClick={() => window.history.back()}
      >
        Back
      </Button>

      <Typography variant="h4" gutterBottom>
        Block Details: #{block.number}
      </Typography>

      <TableContainer component={Paper} sx={{ mb: 3 }}>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell><strong>Hash:</strong></TableCell>
              <TableCell>{block.hash}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell><strong>Parent Hash:</strong></TableCell>
              <TableCell>{block.parentHash}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell><strong>Timestamp:</strong></TableCell>
              <TableCell>{new Date(block.timestamp).toLocaleString()}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell><strong>Miner:</strong></TableCell>
              <TableCell>
                <Link
                  to={`/addresses/${block.miner}`}
                  style={{ color: 'inherit', textDecoration: 'none' }}
                >
                  {block.miner}
                </Link>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell><strong>Difficulty:</strong></TableCell>
              <TableCell>{block.difficulty}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell><strong>Total Difficulty:</strong></TableCell>
              <TableCell>{block.totalDifficulty}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell><strong>Gas Used:</strong></TableCell>
              <TableCell>{block.gasUsed}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell><strong>Gas Limit:</strong></TableCell>
              <TableCell>{block.gasLimit}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell><strong>Size:</strong></TableCell>
              <TableCell>{block.size} bytes</TableCell>
            </TableRow>
            <TableRow>
              <TableCell><strong>Nonce:</strong></TableCell>
              <TableCell>{block.nonce}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>

      <Divider sx={{ my: 2 }} />

      <Typography variant="h5" gutterBottom>
        Transactions in Block ({transactions.length})
      </Typography>

      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell><strong>Transaction Hash</strong></TableCell>
              <TableCell><strong>From</strong></TableCell>
              <TableCell><strong>To</strong></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {transactions.length > 0 ? (
              transactions.map((tx) => (
                <TableRow key={tx.hash}>
                  <TableCell>
                    <Link
                      to={`/transactions/${tx.hash}`}
                      style={{ color: 'inherit', textDecoration: 'none' }}
                    >
                      {shortenHash(tx.hash)}
                    </Link>
                  </TableCell>
                  <TableCell>
                    <Link
                      to={`/addresses/${tx.from}`}
                      style={{ color: 'inherit', textDecoration: 'none' }}
                    >
                      {shortenHash(tx.from)}
                    </Link>
                  </TableCell>
                  <TableCell>
                    <Link
                      to={`/addresses/${tx.to}`}
                      style={{ color: 'inherit', textDecoration: 'none' }}
                    >
                      {tx.to ? shortenHash(tx.to) : 'Contract Creation'}
                    </Link>
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={3} align="center">
                  No transactions found in this block.
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Container>
  );
}

export default BlockDetailsPage;
