import React, { useEffect, useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import { getAddressById, getAddressTransactions, getAddressMinedBlocks } from '../services/api';
import {
  Container,
  Typography,
  CircularProgress,
  Box,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
} from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle'; // ������ ��� ��������� ������
import PendingIcon from '@mui/icons-material/HourglassEmpty'; // ������ ��� ����������� ������

function AddressDetailsPage() {
  const { address } = useParams();
  const [addressData, setAddressData] = useState(null);
  const [transactions, setTransactions] = useState([]);
  const [blocks, setBlocks] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [blockPage, setBlockPage] = useState(1);
  const itemsPerPage = 10;

  useEffect(() => {
    const fetchAddressData = async () => {
      setLoading(true);
      try {
        const response = await getAddressById(address);
        setAddressData(response.data);

        const txResponse = await getAddressTransactions(address);
        setTransactions(txResponse.data);

        const blockResponse = await getAddressMinedBlocks(address, blockPage, itemsPerPage);
        setBlocks(blockResponse.data);

        setError(null);
      } catch (err) {
        console.error("Failed to load address data:", err);
        setError("Failed to load address details.");
      } finally {
        setLoading(false);
      }
    };

    fetchAddressData();
  }, [address, blockPage]);

  if (loading) {
    return (
      <Box display="flex" alignItems="center" justifyContent="center" mt={4}>
        <CircularProgress />
        <Typography sx={{ ml: 2 }}>Loading...</Typography>
      </Box>
    );
  }

  if (error) {
    return <Typography color="error" align="center">{error}</Typography>;
  }

  if (!addressData) return null;

  const handleNextPage = () => setBlockPage((prevPage) => prevPage + 1);
  const handlePreviousPage = () => setBlockPage((prevPage) => Math.max(prevPage - 1, 1));

  return (
    <Container>
      <Typography variant="h4" gutterBottom>Address Details</Typography>
      <Box sx={{ mb: 3 }}>
        <Typography variant="body1">
          <strong>Address:</strong> {addressData.address}
        </Typography>
        <Typography variant="body1">
          <strong>Balance:</strong> {addressData.balance}
        </Typography>
        <Typography variant="body1" style={{ display: 'flex', alignItems: 'center' }}>
          <strong>Activity Status:</strong>
          {addressData.txCount > 0 ? (
            <>
              <CheckCircleIcon sx={{ color: 'green', ml: 1 }} />
              <span style={{ marginLeft: '5px' }}>Active</span>
            </>
          ) : (
            <>
              <PendingIcon sx={{ color: 'orange', ml: 1 }} />
              <span style={{ marginLeft: '5px' }}>Inactive</span>
            </>
          )}
        </Typography>
        <Typography variant="body1">
          <strong>Transactions:</strong> {addressData.txCount}
        </Typography>
      </Box>
      
      <Divider sx={{ my: 2 }} />
      
      <Typography variant="h5" gutterBottom>Transactions</Typography>
      
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Transaction Hash</TableCell>
              <TableCell>Value</TableCell>
              <TableCell>Block</TableCell>
              <TableCell>Status</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {transactions.length > 0 ? (
              transactions.map((tx) => (
                <TableRow key={tx.hash}>
                  <TableCell>
                    <Link to={`/transactions/${tx.hash}`} style={{ color: 'inherit', textDecoration: 'none' }}>
                      {tx.hash}
                    </Link>
                  </TableCell>
                  <TableCell>{tx.value}</TableCell>
                  <TableCell>
                    <Link to={`/block/${tx.blockNumber}`} style={{ color: 'inherit', textDecoration: 'none' }}>
                      {tx.blockNumber}
                    </Link>
                  </TableCell>
                  <TableCell style={{ display: 'flex', alignItems: 'center' }}>
                    {tx.status && tx.status.toLowerCase() === 'confirmed' ? (
                      <>
                        <CheckCircleIcon sx={{ color: 'green', mr: 1 }} />
                        Confirmed
                      </>
                    ) : (
                      <>
                        <PendingIcon sx={{ color: 'orange', mr: 1 }} />
                        Pending
                      </>
                    )}
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={4} align="center">
                  No transactions found for this address.
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>

      <Divider sx={{ my: 4 }} />

      <Typography variant="h5" gutterBottom>Mined Blocks</Typography>

      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Block Number</TableCell>
              <TableCell>Timestamp</TableCell>
              <TableCell>Transaction Count</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {blocks.length > 0 ? (
              blocks.map((block) => (
                <TableRow key={block.number}>
                  <TableCell>
                    <Link to={`/block/${block.number}`} style={{ color: 'inherit', textDecoration: 'none' }}>
                      {block.number}
                    </Link>
                  </TableCell>
                  <TableCell>
                    {block.timestamp ? new Date(block.timestamp).toLocaleString() : "No Date Available"}
                  </TableCell>
                  <TableCell>{block.transactionCount}</TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={3} align="center">
                  No mined blocks found for this address.
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>

      <Box display="flex" justifyContent="center" mt={2}>
        <Button onClick={handlePreviousPage} disabled={blockPage === 1}>Previous</Button>
        <Typography variant="body1" sx={{ mx: 2 }}>Page {blockPage}</Typography>
        <Button onClick={handleNextPage}>Next</Button>
      </Box>
    </Container>
  );
}

export default AddressDetailsPage;
