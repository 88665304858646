import axios from 'axios';

// Запрос для получения статистики сети
export const getNetworkStats = () => 
  axios.get(`/api/stats`);

// Запрос для получения последних блоков с поддержкой limit и offset
export const getLatestBlocks = (limit = 10, offset = 0) => 
  axios.get(`/api/blocks`, {
    params: { limit, offset }
  });

// Запрос для получения последних транзакций
export const getLatestTransactions = (limit = 10, offset = 0) => 
  axios.get(`/api/transactions/latest`, {
    params: { limit, offset }
  });

// Запрос для получения данных о конкретном блоке по его номеру
export const getBlockByNumber = (blockNumber) => 
  axios.get(`/api/blocks/${blockNumber}`);

// Запрос для получения транзакций по номеру блока
export const getTransactionsByBlockNumber = (blockNumber) => 
  axios.get(`/api/blocks/${blockNumber}/transactions`);

// Запрос для получения списка адресов
export const getAddresses = (limit = 10, offset = 0) => 
  axios.get(`/api/addresses`, {
    params: { limit, offset }
  });

// Запрос для получения данных о конкретном адресе по его ID
export const getAddressById = (address) => 
  axios.get(`/api/addresses/${address}`);

// Запрос для получения транзакций, связанных с определенным адресом
export const getAddressTransactions = (address, limit = 10, offset = 0) => 
  axios.get(`/api/addresses/${address}/transactions`, {
    params: { limit, offset }
  });
  
// Запрос для получения данных о конкретной транзакции по её хэшу
export const getTransactionByHash = (hash) =>
  axios.get(`/api/transactions/${hash}`);
  
// Запрос для получения смайненных блоков адресом
export const getAddressMinedBlocks = async (address, page, itemsPerPage) => {
  const response = await axios.get(`/api/addresses/${address}/mined-blocks`, {
    params: { page, itemsPerPage },
  });
  return response;
};
