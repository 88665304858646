// src/components/TransactionCard.js
import React, { useContext } from 'react';
import { Card, CardContent, Typography, Box } from '@mui/material';
import { Link } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import { ColorModeContext } from '../ThemeContext';

function TransactionCard({ transaction }) {
  const theme = useTheme();
  const { mode } = useContext(ColorModeContext);
  const textColor = theme.palette.text.primary;
  const linkColor = theme.palette.primary.main;

  return (
    <Card
      sx={{
        borderRadius: 2,
        boxShadow: '0px 10px 30px rgba(0, 0, 0, 0.1)',
        backgroundColor: theme.palette.background.paper,
        border: `1px solid ${theme.palette.divider}`,
        transition: 'transform 0.3s, box-shadow 0.3s',
        '&:hover': {
          transform: 'scale(1.03)',
          boxShadow: '0px 15px 40px rgba(0, 0, 0, 0.2)',
        },
        padding: '10px',
      }}
    >
      <CardContent>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
          <Box
            sx={{
              width: 10,
              height: 10,
              borderRadius: '50%',
              backgroundColor: linkColor,
            }}
          />
          <Typography
            variant="h6"
            component="div"
            sx={{
              fontWeight: 'bold',
              color: linkColor,
            }}
          >
            <Link
              to={`/transactions/${transaction.hash}`}
              style={{
                textDecoration: 'none',
                color: linkColor,
              }}
            >
              {transaction.hash.slice(0, 10)}...{transaction.hash.slice(-10)}
            </Link>
          </Typography>
        </Box>
        <Typography variant="body2" sx={{ color: textColor, marginTop: 1 }}>
          From: {transaction.from}
        </Typography>
        <Typography variant="body2" sx={{ color: textColor }}>
          To: {transaction.to}
        </Typography>
        <Typography variant="body2" sx={{ color: textColor }}>
          Value: {transaction.value} LBRT
        </Typography>
      </CardContent>
    </Card>
  );
}

export default TransactionCard;
