import React, { useEffect, useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import { getTransactionByHash } from '../services/api';
import {
  Container,
  Typography,
  CircularProgress,
  Box,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Paper,
  Tabs,
  Tab,
} from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle'; // ������ ��� �������������� �������
import PendingIcon from '@mui/icons-material/HourglassEmpty'; // ������ ��� ������� Pending

function TransactionDetailsPage() {
  const { hash } = useParams();
  const [transaction, setTransaction] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [tabIndex, setTabIndex] = useState(0);

  useEffect(() => {
    const fetchTransaction = async () => {
      setLoading(true);
      try {
        const response = await getTransactionByHash(hash);
        setTransaction(response.data);
        setError(null);
      } catch (err) {
        console.error('Failed to load transaction:', err);
        setError('Failed to load transaction details.');
      } finally {
        setLoading(false);
      }
    };

    fetchTransaction();
  }, [hash]);

  if (loading) {
    return (
      <Box display="flex" alignItems="center" justifyContent="center" mt={4}>
        <CircularProgress />
        <Typography sx={{ ml: 2 }}>Loading...</Typography>
      </Box>
    );
  }

  if (error) {
    return <Typography color="error" align="center">{error}</Typography>;
  }

  if (!transaction) return null;

  const handleTabChange = (event, newIndex) => setTabIndex(newIndex);

  return (
    <Container>
      <Typography variant="h4" gutterBottom>Transaction Details</Typography>

      {/* �������� ������ � ���������� */}
      <TableContainer component={Paper} sx={{ mb: 2 }}>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell><strong>Transaction Hash:</strong></TableCell>
              <TableCell>{transaction.hash}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell><strong>Status:</strong></TableCell>
              <TableCell style={{ display: 'flex', alignItems: 'center' }}>
                {transaction.status && transaction.status.toLowerCase() === 'confirmed' ? (
                  <>
                    <CheckCircleIcon sx={{ color: 'green', mr: 1 }} />
                    Confirmed
                  </>
                ) : (
                  <>
                    <PendingIcon sx={{ color: 'orange', mr: 1 }} />
                    Pending
                  </>
                )}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell><strong>Block Number:</strong></TableCell>
              <TableCell>
                <Link to={`/block/${transaction.blockNumber}`} style={{ color: 'inherit', textDecoration: 'none' }}>
                  {transaction.blockNumber}
                </Link>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell><strong>From:</strong></TableCell>
              <TableCell>
                <Link to={`/addresses/${transaction.from}`} style={{ color: 'inherit', textDecoration: 'none' }}>
                  {transaction.from}
                </Link>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell><strong>To:</strong></TableCell>
              <TableCell>
                {transaction.to ? (
                  <Link to={`/addresses/${transaction.to}`} style={{ color: 'inherit', textDecoration: 'none' }}>
                    {transaction.to}
                  </Link>
                ) : 'Contract Creation'}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell><strong>Value:</strong></TableCell>
              <TableCell>{Number(transaction.value).toFixed(8)} LBRT</TableCell>
            </TableRow>
            <TableRow>
              <TableCell><strong>Gas:</strong></TableCell>
              <TableCell>{transaction.gas}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell><strong>Gas Price:</strong></TableCell>
              <TableCell>{transaction.gasPrice}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell><strong>Nonce:</strong></TableCell>
              <TableCell>{transaction.nonce}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>

      <Divider sx={{ my: 2 }} />

      {/* ������� ��� ����������� �������������� ������ */}
      <Tabs value={tabIndex} onChange={handleTabChange} centered>
        <Tab label="Internal Transactions" />
        <Tab label="Logs" />
        <Tab label="State Changes" />
      </Tabs>

      {/* ����������� �������� ��� ��������� ������� */}
      {tabIndex === 0 && (
        <Box mt={2}>
          <Typography variant="h6">Internal Transactions</Typography>
          <Typography variant="body1" color="textSecondary">No internal transactions available.</Typography>
        </Box>
      )}
      {tabIndex === 1 && (
        <Box mt={2}>
          <Typography variant="h6">Logs</Typography>
          <Typography variant="body1" color="textSecondary">No logs available.</Typography>
        </Box>
      )}
      {tabIndex === 2 && (
        <Box mt={2}>
          <Typography variant="h6">State Changes</Typography>
          {transaction.stateChanges && transaction.stateChanges.length > 0 ? (
            <TableContainer component={Paper}>
              <Table>
                <TableBody>
                  <TableRow>
                    <TableCell><strong>Address</strong></TableCell>
                    <TableCell><strong>Balance Before</strong></TableCell>
                    <TableCell><strong>Balance After</strong></TableCell>
                    <TableCell><strong>Change</strong></TableCell>
                  </TableRow>
                  {transaction.stateChanges.map((change, index) => (
                    <TableRow key={index}>
                      <TableCell>{change.address}</TableCell>
                      <TableCell>
                        {change.balanceBefore != null
                          ? Number(change.balanceBefore).toFixed(8)
                          : 'N/A'} LBRT
                      </TableCell>
                      <TableCell>
                        {change.balanceAfter != null
                          ? Number(change.balanceAfter).toFixed(8)
                          : 'N/A'} LBRT
                      </TableCell>
                      <TableCell>
                        {change.change != null
                          ? Number(change.change).toFixed(8)
                          : 'N/A'} LBRT
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          ) : (
            <Typography variant="body1" color="textSecondary">
              No state changes available for this transaction.
            </Typography>
          )}
        </Box>
      )}
    </Container>
  );
}

export default TransactionDetailsPage;
